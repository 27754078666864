/* website: 2458-morreynissancoquitlam2
 * created at 2023-02-21 11:30 mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/nissan.scss";
@import "../utils/icons.scss";

.widget-sr{
    @include clearfix;
    width: 100%;
    &.dealer__morrey-nissan-coquitlam{
        .listing-tile .vehicle-certification .certification-badge-carfax{
            flex-direction: row;
            align-items: center;
        }
        .listing-tile .certification-badge__image{
            align-self: center;
        }
        .carproof-badge{
            width: 100%;
        }
        .listing-tile{
            .listing-tile-car-save{
                left: 0;
                right: auto;
                top: 40px;
            }
        }
    }
}
